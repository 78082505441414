<template lang="pug">
main(v-if='response', data-view)
  section.hero
    prismic-image.hero__photo(:field='response.data.coach_image')
    .hero__meta
      prismic-rich-text.hero__meta__title(:field='response.data.coach_title')
      prismic-rich-text.hero__meta__text(:field='response.data.coach_text')
      img(src='@/assets/logo sciences po.png', alt='logo science po')
      img(src='@/assets/Logo dauphine.png', alt='logo dauphine')
      img(src='@/assets/Logo essec.png', alt='logo essec')
      img(src='@/assets/badge.png', alt='logo pcc')
      img(src='@/assets/Logo INC.png', alt='logo Inc')
      img(src='@/assets/logo IFHE bleu.png', alt='logo ifhe')

  section.mission
    .mission__meta
      prismic-rich-text.mission__meta__title(:field='response.data.mission_title')
      prismic-rich-text.mission__meta__text(:field='response.data.mission_text')
    prismic-embed.mission__video(:field='response.data.mission_video')
    
  section.hero(v-if='response.data.second_coach_title')
    prismic-image.hero__photo(:field='response.data.second_coach_image')
    .hero__meta
      prismic-rich-text.hero__meta__title(:field='response.data.second_coach_title')
      prismic-rich-text.hero__meta__text(:field='response.data.second_coach_text')

  template(v-for='(secondaryCoach, index) in secondaryCoachesSlices')
    section.hero.hero--green(:key='"secondaycoach" + index')
      prismic-image.hero__photo(:field='secondaryCoach.primary.coach_portrait')
      .hero__meta
        prismic-rich-text.hero__meta__title(:field='secondaryCoach.primary.coach_name')
        prismic-rich-text.hero__meta__text(:field='secondaryCoach.primary.coach_description')

  section.goals
    .goals__goal(v-for='goal in response.data.goals')
      .goals__goal__illustration
        prismic-image(:field='goal.goal_illustration')
      h3.goals__goal__title {{ goal.goal_title[0].text }}
      p.goals__goal__text {{ goal.goal_text[0].text }}
</template>

<script>
export default {
  name: 'Coach',
  components: {
    CourseAbstractCard: () => import('@/components/CourseAbstractCard'),
    CommonButton: () => import('@/components/CommonButton'),
    MissionCard: () => import('@/components/MissionCard')
  },

  data() {
    return {
      response: null
    }
  },
  computed: {
    secondaryCoachesSlices() {
      return this.response.data.body.filter((slice) => slice.slice_type === 'secondary_coach')
    }
  },
  created() {
    this.getContent()
  },
  metaInfo() {
    return {
      title: this.response && this.response.data.title,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.response && this.response.data.description
        }
      ]
    }
  },
  methods: {
    async getContent() {
      const response = await this.$prismic.client.getSingle('coach')
      this.response = response
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/colors';
@import '@/styles/mixins';
@import '@/styles/vars';

.hero {
  display: flex;
  flex-direction: column;
  margin: 0 -1.5rem;
  padding: 1.5rem;
  background-color: rgba($lightBlue, 0.4);
  border-radius: 0 0 20px 20px;
  margin-bottom: 3rem;
  justify-content: space-between;

  &--green {
    background-color: rgba($green, 0.4);
  }

  &__photo {
    max-width: 100%;
    height: 300px;
    width: 300px;
    object-fit: cover;
    object-position: top;
    border-radius: 20px 20px 20px 0;
    margin-bottom: 2rem;
    margin-right: 2rem;
  }
  &__meta {
    &__title {
      font-size: 2rem;
      line-height: 2rem;
      color: $darkBlue;
      margin-bottom: 1rem;
      text-transform: uppercase;
      &::v-deep {
        h1 {
          font-family: 'Bebas Neue', sans-serif;
          font-weight: 500;
        }
      }
    }

    img {
      height: 30px;
      margin-right: 1rem;
      margin-bottom: 1rem;
    }
  }

  @include medium-screen {
    position: relative;
    flex-direction: row;
    margin: 0;
    margin-bottom: 3rem;
    padding: 4rem 0;
    background-color: transparent;
    align-items: center;

    &__meta {
      box-sizing: border-box;
      flex-basis: 60%;
      padding-right: 4rem;

      &__title {
        font-size: 3rem;
        line-height: 4rem;
      }
    }

    &::after {
      content: '';
      height: 100%;
      width: 90%;
      position: absolute;
      background-color: rgba($lightBlue, 0.4);
      right: 0;
      margin: -2rem (-$mainHorizontalPaddingMedium) -2rem 0;
      z-index: -1;
      border-radius: 20px 0 0 20px;
    }

    &--green {
      &::after {
        background-color: rgba($green, 0.4);
      }
    }
  }

  @include large-screen {
    padding: 5rem 0;

    &::after {
      width: 100%;
      margin: -2rem (-$mainHorizontalPaddingLarge) -2rem 0;
    }
  }
}

.mission {
  &__meta {
    &__title {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
  }

  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  &__video {
    width: calc(100% + #{$mainHorizontalPadding});
    height: 300px;
    align-self: flex-end;
    margin-right: -$mainHorizontalPadding;
    border-radius: 20px 0 0 20px;
    overflow: hidden;
    &::v-deep {
      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }

  @include medium-screen {
    flex-direction: row;
    align-items: center;
    &__meta {
      flex-basis: 100%;
      margin-right: 2rem;
      &__title {
        font-size: 2rem;
      }
    }

    &__video {
      width: calc(100% + #{$mainHorizontalPaddingMedium});
      margin-right: -$mainHorizontalPaddingMedium;
      flex-grow: 1;
    }
  }

  @include large-screen {
    &__video {
      height: 350px;
      width: calc(100% + #{$mainHorizontalPaddingLarge});
      margin-right: -$mainHorizontalPaddingLarge;
      flex-grow: 1;
    }
  }
}

.goals {
  display: flex;
  flex-wrap: wrap;

  &__goal {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
    }
    &__illustration {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      box-shadow: 0px 3px 16px 0px rgba(49, 49, 49, 0.2);
      margin-bottom: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }

  @include medium-screen {
    padding: -1rem;

    > * {
      margin: 2rem;
    }
  }

  > * {
    text-align: center;
    flex-basis: 25%;
    flex-grow: 1;
    min-width: 300px;
  }
}
</style>
